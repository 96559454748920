/* App.css */
@tailwind base;
@tailwind components;
@tailwind utilities;

.App {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;
  background-color: #f0f0f0;
}

